<template>
  <div id="ProfileCompetenceWrapper">
    <div
      class="container"
      :class="
        `${this.header ? 'padding-container' : ''} ${
          this.viewMode == true ? 'CheckTaskZeroMarginPadding' : ''
        }`
      "
    >
      <div class="CompetenceHeader" v-if="viewMode == false">
        <h2 v-if="this.header">
          Необходимые компетенции
          <CircleQuestionIcon
            v-if="competitionTip"
            @click="showComponentDesc = !showComponentDesc"
          />
        </h2>

        <div class="notice" v-if="showNotice == true">
          <div class="tags-p">
            У вас закончена одна компетенция <br />
            <p class="notice-p">
              Необходимо сдать отчет по компетенции Image processing
            </p>
          </div>
          <button class="pass-btn">
            Сдать отчет
          </button>
        </div>

        <div class="CompetitionTip" v-if="competitionTip && showComponentDesc">
          <span class="Header">Что такое необходимые компетенции?</span>
          <span class="Text"
            >Чтобы приступить к выполнению задачи, тебе нужно получить
            необходимые знания по направлениям, которые необходимы для
            реализации выбранной задачи</span
          >
        </div>

        <!-- <div class="tab-btn">
          <button
            v-for="button in competence"
            :key="button.name"
            :class="activeButton === button.name ? 'activeButton' : ''"
            @click="
              activeBlock = button.CBlock;
              activeButton = button.name;
            "
          >
            {{ `${button.name} (${button.counter})` }}
          </button>
        </div> -->
      </div>
      <div class="comp-lvls">
        <div
          v-for="comp in activeBlock"
          :key="comp"
          :class="
            `comp-lvl ${
              this.viewMode == true ? 'CheckTaskZeroMarginPadding' : ''
            }`
          "
          :style="
            this.viewMode
              ? ''
              : comp.isCompleteSection
              ? ''
              : `opacity: 0.5;
  pointer-events: none;`
          "
        >
          <div class="compet">{{ comp.name }}</div>

          <div
            v-if="!comp.isCompleteSection && viewMode == false"
            class="compet notAvailable"
          >
            Чтобы начать выполнение, завершите предыдущую секцию
          </div>

          <span class="compet-blocks">
            <span
              v-for="(block, indx) in comp.levels"
              :key="block"
              :style="styleForTooltip"
              @mousemove="setStyleForTooltip($event)"
              data-tooltip
              :data-tooltip-label="
                block.name == ''
                  ? `Уровень ${indx + 1}`
                  : `Уровень ${indx + 1}: ${block.name}`
              "
              :data-tooltip-message="block.description"
              @click="clickHandler(comp.id, block.id)"
            >
              <div
                :class="{
                  lvl: true,
                  'lvl-painted': block.ready === true,
                  'lvl-for-project': useAlternateMethod
                    ? whiteListRequireBlock.includes(block.id)
                    : block.isNeedForProj === true,
                }"
              ></div>
            </span>
          </span>

          <template
            v-if="
              getRedirectButton(comp.levels) == 'start' && viewMode == false
            "
          >
            <router-link
              style="margin-left: auto;"
              class="MobileAdapt"
              :to="`/edu/about/${comp.id}`"
              ><div class="start-btn">
                <span>Начать обучение</span>
              </div></router-link
            >
          </template>

          <template
            v-else-if="
              getRedirectButton(comp.levels) == 'continue' && viewMode == false
            "
          >
            <router-link
              style="margin-left: auto;"
              class="MobileAdapt"
              :to="`/edu/about/${comp.id}`"
              ><div class="start-btn"><span>Продолжить</span></div></router-link
            >
          </template>

          <template
            v-else-if="
              getRedirectButton(comp.levels) == 'finished' && viewMode == false
            "
          >
            <router-link
              style="margin-left: auto;"
              class="MobileAdapt"
              :to="`/edu/about/${comp.id}`"
              ><div class="start-btn">
                <span>Сдать отчет</span>
              </div></router-link
            >
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.CheckTaskZeroMarginPadding {
  margin-left: 0 !important;
  padding-left: 0 !important;
}

.CompetenceHeader {
  position: relative;
  width: 100%;
  height: auto;
  overflow: auto;
  flex-shrink: 0;
}

.compet-blocks {
  position: relative;
  display: flex;
  flex-direction: row;
  margin-right: 24px;
}

.compet-blocks > span:not(:nth-last-child(1)) {
  margin-right: 8px;
}

.start-btn {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 6px 16px;
  border: 1px solid #413e74;
  border-radius: 4px;
  color: #e8e7ec;
  font-weight: 400;
  font-size: 15px;
  line-height: 155%;
  flex-shrink: 0;
}

.start-btn > span {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;
  margin: auto;

  color: #e8e7ec;
  font-weight: 400;
  font-size: 15px;
  line-height: 155%;
}

.lvl:hover {
  border: 1px solid #b434b5;
}

.lvl {
  display: flex;
  width: 36px;
  height: 32px;
  background: #312e57;
  border-radius: 30%;
}

.lvl-for-project {
  border: 2px solid #b434b5;
}
.lvl-painted {
  background: #b434b5;
}
.compet {
  margin-right: 24px;
  margin-top: auto;
  margin-bottom: auto;
  width: 100%;
  height: fit-content;
  color: #e8e7ec;
  font-weight: 500;
  font-size: 20px;
  line-height: 155%;
  text-align: left;

  flex-shrink: 0;
}
.comp-lvl span {
  margin-top: auto;
  margin-bottom: auto;
}
.comp-lvl {
  display: flex;
  margin-left: 12px;
  padding: 11.5px 0px;
  flex-wrap: wrap;
  row-gap: 4px;
}
.comp-lvls {
  position: relative;
  height: auto;
  overflow-y: scroll;
  overflow-x: hidden;

  margin-top: 16px;
}
.tab-btn button {
  background-color: #413e74;
  color: #ffffff;
}
.tab-btn button.activeButton {
  background-color: #413e74;
  color: #ffffff;
}

.tab-btn button {
  display: left;
  flex-direction: row;
  align-items: flex-start;
  padding: 5px 15px;
  background: none;
  border-radius: 4px;
  border: 1px solid #413e74;
  color: #e8e7ec;
  font-weight: 400;
  font-size: 15px;
  line-height: 155%;
  margin-right: 8px;
}
.tab-btn {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  row-gap: 10px;

  margin-top: 24px;
  margin-bottom: 10px;
}

.pass-btn {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 6px 16px;
  background-color: #413e74;
  border-radius: 4px;
  color: #e8e7ec;
  border: none;
  font-weight: 400;
  font-size: 15px;
  line-height: 155%;
}
.notice-p {
  font-weight: 400;
  font-size: 15px;
  line-height: 155%;
  color: #c8c3de;
  margin: 0px;
  margin-top: 4px;
}
.tags-p {
  color: #e8e7ec;
  margin: 0px;
  font-weight: 500;
  font-size: 15px;
  line-height: 155%;
}
.notice {
  background-color: #292558;
  margin-top: 16px;
  padding: 12px 24px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 15px;
}

h2 {
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #e8e7ec;
}

.container {
  position: relative;
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;

  color: #c8c3de;
  font-family: "Montserrat";

  height: max-content;
}

.padding-container {
  padding: 0px 0px 0px 0px;
}

#ProfileCompetenceWrapper {
  position: relative;
  width: 100%;
  height: 100%;

  overflow-y: auto;
}
</style>

<style scoped>
[data-tooltip] {
  position: relative;
}

[data-tooltip]:before,
[data-tooltip]:after {
  display: none;
  position: absolute;
  z-index: 800;
}

[data-tooltip]:before {
  border-bottom: 0.6em solid #453378;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  content: "";
  left: calc(var(--tooltip-client-x) - 6px);
  bottom: calc(100vh - var(--tooltip-client-y) + 22px);
  transform: rotate(180deg);
}

[data-tooltip]:after {
  background: #453378;
  border-radius: 8px;
  content: attr(data-tooltip-label) "\A\A"attr(data-tooltip-message);
  left: 0;
  padding: 5px 15px;
  white-space: pre-wrap;
  width: 404px;
  left: calc(var(--tooltip-client-x) - 40px);
  bottom: calc(100vh - var(--tooltip-client-y) + 30px);

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 160%;
  box-shadow: 8px 0px 8px rgba(31, 18, 35, 0.15);

  /* or 24px */
  letter-spacing: 0.01em;

  color: #f5f4f8;
}

[data-tooltip]:hover:after,
[data-tooltip]:hover:before {
  display: block;
  position: fixed;
}
</style>

<style scoped>
@media (max-width: 480px) {
  .MobileAdapt {
    width: 100%;
  }

  .tab-btn button {
    font-size: 12px;
    line-height: 155%;
  }
  .tab-btn {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    row-gap: 10px;

    margin-top: 24px;
    margin-bottom: 10px;
  }

  h2 {
    font-size: 18px;
    line-height: 22px;
  }

  .compet {
    margin-right: 24px;
    width: 190px;
    height: 31px;
    font-size: 14px;
    line-height: 155%;

    flex-shrink: 0;
  }

  .lvl {
    width: 20px;
    height: 18px;
  }
}
@media (min-width: 480px) and (max-width: 768px) {
  .MobileAdapt {
    width: 100%;
  }
}
</style>

<style scoped>
.compet.notAvailable {
  margin-bottom: 8px;

  font-family: "Montserrat";
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: left;
  color: rgba(211, 202, 255, 0.75);
}

.CompetitionTip {
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;

  margin-top: 10px;
  margin-bottom: 15px;

  padding: 20px 30px;

  width: 100%;
  height: fit-content;

  border-radius: 20px;
  background: #292558;
}
.CompetitionTip > .Header {
  position: relative;
  display: block;

  width: fit-content;

  color: #fafafa;

  /* text medium */
  font-family: "Montserrat";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 155%; /* 23.25px */
}
.CompetitionTip > .Text {
  position: relative;
  display: block;

  width: 100%;

  color: #c8c3de;
  font-family: "Montserrat";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
</style>

<script>
import { getSections } from "@/api/competence.js";
import CircleQuestionIcon from "@/components/CustomElements/CircleQuestionIcon.vue";

export default {
  components: {
    CircleQuestionIcon,
  },
  props: {
    header: {
      type: Boolean,
      default: function() {
        return false;
      },
    },
    projectLevels: {
      type: Array,
      default: function() {
        return [];
      },
    },
    userLevels: {
      type: Array,
      default: function() {
        return [];
      },
    },
    viewMode: {
      type: Boolean,
      default: function() {
        return false;
      },
    },

    //Содержит ID компетенций, которые нужно выводить в списке
    competenceWhiteList: {
      type: Array,
      default: function() {
        return [];
      },
    },
    whiteListRequireBlock: {
      type: Array,
      default: function() {
        return [];
      },
    },
    useAlternateMethod: {
      type: Boolean,
      default: false,
    },
    useWhiteListRequireBlock: {
      type: Boolean,
      default: function() {
        return false;
      },
    },

    competitionTip: {
      type: Boolean,
      default: function() {
        return false;
      },
    },

    clickHandler: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      activeBlock: [],
      activeButton: "",
      competence: [],
      showNotice: false,
      showComponentDesc: false,
      tooltip: {
        X: -9000,
        Y: -9000,
      },
    };
  },
  name: "CompetenceBlock",
  computed: {
    styleForTooltip() {
      return `--tooltip-client-y: ${this.tooltip.Y}px; --tooltip-client-x: ${this.tooltip.X}px;`;
    },
  },
  mounted() {
    //заполнение блока компетенций направлениями, уровнями пользователя и необходимыми уровнями для проекта
    getSections().then((response) => {
      let _sections = response.data;

      let sections = this.competenceWhiteList.map((wSectID) => {
        let _f = _sections.find((s) => s.id === wSectID);
        return _f;
      });

      if (this.competenceWhiteList.length > 0) {
        sections = sections.filter((sect) => {
          let sect_id = sect.id;
          let a = this.competenceWhiteList.find((el) => el == sect_id);
          return a != null ? true : false;
        });
      }

      let tags = new Set(sections.map((section) => section.tag));
      tags.forEach((tag) => {
        let filteredSections = sections
          .filter((section) => section.tag === tag)
          .map((section, indx) => {
            return {
              name: section.name,
              id: section.id,
              levels: section.degrees
                .flatMap((degree) => degree.levels.reverse())
                .reverse()
                .map((level, lindx) => {
                  level.isNeedForProj = this.isNeedForProj(
                    indx,
                    lindx,
                    level.id
                  );
                  level.ready =
                    this.userLevels.indexOf(level.id) === -1 ? false : true;
                  return level;
                }),
            };
          });
        this.competence.push({
          name: tag,
          counter: filteredSections.length,
          CBlock: filteredSections,
        });
      });
      if (this.competence[0]) {
        let activeTab = this.competence[0].CBlock;

        for (let i = 0; i < activeTab.length; i++) {
          let sect = activeTab[i];

          // levels
          let levelsRequired = sect.levels
            .filter((x) => this.whiteListRequireBlock.includes(x.id))
            .filter((x) => !x.ready);

          sect["isCompleteSection"] = false;
          sect["stopAutoComplete"] = false;

          if (i === 0) {
            sect["isCompleteSection"] = true;

            if (levelsRequired.length !== 0) {
              sect["stopAutoComplete"] = true;
            }
            continue;
          }

          if (
            activeTab[i - 1]["isCompleteSection"] === true &&
            activeTab[i - 1]["stopAutoComplete"] === false
          ) {
            sect["isCompleteSection"] = true;

            if (levelsRequired.length !== 0) {
              sect["stopAutoComplete"] = true;
            }
          }
        }

        this.activeBlock = activeTab;
        this.activeButton = this.competence[0].name;
      }
    });
  },
  methods: {
    restoreOrder(a, b) {
      // Создаем объект для хранения индексов элементов из массива a
      const indexMap = {};
      a.forEach((value, index) => {
        indexMap[value] = index;
      });

      // Сортируем массив b по индексам из объекта
      const bSorted = b.sort((x, y) => indexMap[x] - indexMap[y]);

      return bSorted;
    },

    getLvlsList(lvls) {
      if (lvls == null) {
        return [];
      }

      return lvls.map((x) => x.id);
    },
    getRedirectButton(array) {
      let started = 0;
      for (let lvl of array) {
        if (lvl.ready == true) {
          started += 1;
        }
      }

      if (started == 0) {
        return "start";
      } else if (array.length == started) {
        return "finished";
      } else {
        return "continue";
      }
    },
    cutLongText(Text) {
      if (Text == null) {
        return "";
      }

      if (Text.length >= 111) {
        return `${Text.substr(0, 108)}...`;
      } else {
        return Text;
      }
    },

    setStyleForTooltip(event) {
      this.tooltip.X = event.x;
      this.tooltip.Y = event.y;
    },

    isNeedForProj(indx, lindx, levelID) {
      if (this.useWhiteListRequireBlock) {
        if (this.whiteListRequireBlock.length > indx) {
          if (this.whiteListRequireBlock[indx].length > lindx) {
            return this.whiteListRequireBlock[indx][lindx];
          }
        } else {
          return false;
        }
      } else {
        return this.projectLevels.indexOf(levelID) === -1 ? false : true;
      }
    },
  },
};
</script>
